import { Link } from 'gatsby'
import React, { FC } from 'react'
import { PageTemplate } from '../components/pageTemplate'

// http://www.dktech.cz/?page_id=26

const Pravidla: FC = () => {
  return (
    <PageTemplate>
      <div className="topTitle">
        <h1><Link to="/pravidla">Pravidla</Link></h1>
      </div>
      <p>Pravidla hry Lineage II na serveru DKTech.cz (dále jen „server“)</p>
      <p>Níže uvedené je jediné oficiální, správné, aktuální a platné znění pravidel serveru DKTech.cz platné ode dne 1. března 2014.</p>
      <p style={{ textAlign: 'center' }}><strong>Čl. 1</strong></p>
      <p style={{ textAlign: 'center' }}>Připojení do hry</p>
      <p>(1) K připojení na server je povoleno použít pouze herního klienta Lineage II C4 Scions of Destiny s povoleným herním systém patchem; odkaz ke stažení je uveden na webových stránkách v sekci „Jak do hry“.</p>
      <p>(2) Počet klientů, kterými se hráč může připojit na server, není omezen.</p>
      <p>(3) Hráči nesmějí svojí činností na serveru žádným způsobem profitovat.</p>
      <p style={{ textAlign: 'center' }}><strong>Čl. 2</strong></p>
      <p style={{ textAlign: 'center' }}>Nakládání s herními účty a postavami</p>
      <p>(1) Každý hráč je plně zodpovědný za všechny své účty a nese za ně plnou odpovědnost.</p>
      <p>(2) Udělení banu postavě a udělení IP banu na vlastní žádost nejsou přípustné.</p>
      <p>(3) Titul, jméno postavy a jméno mazlíčka nesmí obsahovat vulgarismy, nesmí být urážlivé nebo mít hanlivou povahu.</p>
      <p>(4) Titul a jméno postavy musí být takové, aby nemohlo dojít k záměně hráče a GM či admina.</p>
      <p>(5) Používání dalších programů usnadňujících nebo ovlivňujících hru a vlastních úprav klienta je přísně zakázáno.</p>
      <p style={{ textAlign: 'center' }}><strong>Čl. 3</strong></p>
      <p style={{ textAlign: 'center' }}>Základní pravidla chování hráčů</p>
      <p>(1) Hráči jsou povinni dodržovat zákony České republiky.</p>
      <p>(2) Využívání chyb hry nebo serveru je zakázáno. Povinností každého hráče je neprodlené oznámit nalezení chyby.</p>
      <p>(3) Hráči jsou povinni neprodleně oznámit porušování pravidel jiným hráčem. Přitom musí provést dostupné úkony k zadokumentování přestupku (např. screen obrazovky). Součástí oznámení musí být jméno přestupce, popis přestupku, přibližný čas přestupku a místo jeho spáchání.</p>
      <p>(4) Hráči jsou povinni chovat se ve hře slušně a tak, aby svými činy neznepříjemňovali hraní ostatním.</p>
      <p>(5) Hanobení a urážky rasy, národnosti, politického přesvědčení, vyznání nebo sexuální orientace jsou zakázány.</p>
      <p>(6) Vydávání se za GM nebo admina je zakázáno.<br /> <b></b></p>
      <p style={{ textAlign: 'center' }}><strong>Čl. 4</strong></p>
      <p style={{ textAlign: 'center' }}>Další pravidla</p>
      <p>(1) Hráči jsou povinni řídit se pokyny game mastera (dále jen „GM“).</p>
      <p>(2) Hráč může slušnou a adekvátní formou požádat GM a admina o informaci, radu nebo pomoc s řešením problému.</p>
      <p>(3) Hráči nemají nárok na řešení vykradení účtu nebo jiné způsobené ztráty, dalo-li se takové události předejít odpovědným chováním; to nezbavuje pachatele činu odpovědnosti.</p>
      <p>(4) Hráči nesmí napadat GM, není-li to výslovně dovoleno.</p>
      <p>(5) Poškozování herní postavy GM z důvodu předchozího výkonu práv a povinností GM je zakázáno.</p>
      <p>(6) Při pořádání eventů platí další pravidla uvedená na webových stránkách serveru v sekci „eventy“. Hráč je před přihlášením k účasti v eventu povinnen se s těmito pravidly seznámit a dodržovat je.</p>
      <p>(7) Zabíjení ostatních hráčů v mírových zónách a městech pomocí NPC je zakázáno.</p>
      <p>(8) Hráči nesmí ve hře nebo na fóru serveru šířit fámy a pomluvy o serveru, adminech a GM.</p>
      <p>(9) Propagovat ve hře jiné hry nebo servery je nepřípustné.</p>
      <p>(10) Ve hře je zakázáno obtěžovat jiné hráče spammováním.</p>
      <p style={{ textAlign: 'center' }}><strong>Čl. 5</strong></p>
      <p style={{ textAlign: 'center' }}>Řešení porušení pravidel</p>
      <p>(1) Hráči jsou povinni spolupracovat s GM při vyšetřování a objasňování případů porušení pravidel. Odmítnutím spolupráce s GM se má za to, že se hráč jednání, které mu je kladeno za vinu, dopustil.</p>
      <p>(2) Opakované porušení pravidel, snaha o obcházení pravidel nebo trestů jsou přitěžující okolností.</p>
      <p>(3) Hráč může admina písemně na e-mail <a href="mailto:gpl2help@gmail.com">gpl2help@gmail.com</a> (dále jen kontaktní e-mail) požádat o přezkoumání trestu uloženého GM. Přitom musí pravdivě uvést všechny údaje potřebné k řádnému a objektivnímu posouzení věci. Admin může žádost o přezkoumání bez uvedení důvodu odmítnout.</p>
      <p style={{ textAlign: 'center' }}><strong>Čl. 6</strong></p>
      <p style={{ textAlign: 'center' }}>Tresty</p>
      <p>(1) Druhy a výše trestů jsou ukládány podle nejlepšího vědomí a svědomí, vždy s ohledem na závažnost, míru zavinění a dříve uložené tresty.</p>
      <p>(2) GM může podle svého uvážení za porušení pravidel uložit tyto tresty:</p>
      <p style={{ paddingLeft: '30px' }}>a) napomenutí,</p>
      <p style={{ paddingLeft: '30px' }}>b) zákaz používání chatu (stopsay),</p>
      <p style={{ paddingLeft: '30px' }}>c) pokuta,</p>
      <p style={{ paddingLeft: '30px' }}>d) uvěznění postavy.</p>
      <p>(3) Admin může uložit za porušení pravidel tresty uvedené v odst. 2. Za závažné porušení pravidel může uložit dále tyto tresty:</p>
      <p style={{ paddingLeft: '30px' }}>a) ztráta majetku postavy nebo jeho části,</p>
      <p style={{ paddingLeft: '30px' }}>b) ztráta zkušeností a dovedností postavy,</p>
      <p style={{ paddingLeft: '30px' }}>c) dočasný ban postavy,</p>
      <p style={{ paddingLeft: '30px' }}>d) ban postavy,</p>
      <p style={{ paddingLeft: '30px' }}>e) ban hráče.</p>
      <p>(4) Uložené tresty se vedou v rejstříku trestů, který je zveřejňován na webových stránkách serveru.</p>
      <p style={{ textAlign: 'center' }}><strong>Čl. 7</strong></p>
      <p style={{ textAlign: 'center' }}>Další ustanovení</p>
      <p>(1) Všechna oznámení podle těchto pravidel mohou hráči učinit GM postavě ve hře, na fóru serveru nebo na kontaktní e-mail.</p>
      <p>(2) Vedení serveru tvoří admini a GM. Vedení serveru nenese odpovědnost za škody vzniklé užíváním služeb serveru. Vedení serveru jako kolektivní orgán má neomezenou rozhodovací pravomoc.</p>
      <p>(3) Admin nevrací ztracené předměty kromě případů zaviněných výjimečným stavem serveru. Žádost o navrácení musí být podána z registračního e-mailu do 48 hodin na kontaktní e-mail.</p>
      <p>(4) Práva a povinnosti GM ve hře a vůči vedení serveru upravuje GM kodex.</p>
      <p>(5) GM a admin může povolit porušování některých pravidel, je-li to v zájmu objasnění přestupku jiného hráče nebo existují-li k tomu okolnosti hodné zvláštního zřetele.</p>
      <p>(6) GM a admin mohou vyzvat hráče, aby v přiměřené době zvolil jiný <span style={{ fontSize: '13px' }}>titul, </span><span style={{ fontSize: '13px' }}>jméno své postavy  nebo jméno mazlíčka. </span></p>
      <p style={{ textAlign: 'center' }}><strong>Čl. 8</strong></p>
      <p style={{ textAlign: 'center' }}>Přechodná a závěrečná ustanovení</p>
      <p>(1) Hráč postavy nesplňující podmínku podle čl. 2 odst. 3 může ze svého registračního e-mailu ve lhůtě 30 dní ode dne nabytí účinnosti těchto pravidel zaslat na kontaktní e-mail žádost o změnu jména postavy. V žádosti uvede hráč nevyhovující jméno postavy a nové jméno postavy. Nebude-li nové jméno splňovat podmínky podle těchto pravidel, admin změnu jména neprovede. Postavy nesplňující uvedenou podmínku budou bez milosti uvrženy do vězení.</p>
      <p>(2) Aplikace pravidel se řídí zásadami uplatňovanými v právním řádu České republiky.</p>
      <p>(3) Tato pravidla nabývají platnosti a účinnosti dnem 1. března 2014.</p>
      <div className="cleared"></div>
    </PageTemplate>
  )
}

export default Pravidla

export { Head } from '../components/defaultHtmlHead'